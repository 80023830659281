
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '../../utils/formatDate'
import Echarts from 'echarts'

@Component
export default class BraceletDetailDrawing extends Vue {
  private date: string[] = []
  private info = {
    workerId: this.$route.params.workerId,
    startTime: '',
    endTime: ''
  };

  private healthData = {
    abnormalSum: {
      sosAmount: 0,
      abRateAmount: 0,
      abPressureAmount: 0
    }
  }

  private lineHealthData: any = {}
  private heartLineEchart: any = null
  private bloodEcharts: any = null

  created () {
    this.defaultDate()
    this.getData()
    window.addEventListener('resize', this.eChartResize)
  }

  destroyed () {
    this.destoryCharts()
  }

  defaultDate () {
    // 默认最近一个月
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
    this.date = [dayFormat(start.getTime()), dayFormat(end.getTime())]
    this.getLineData()
  }

  getData () {
    const info = {
      workerId: this.info.workerId,
      startTime: this.date[0],
      endTime: this.date[1]
    }
    this.$axios.get(this.$apis.bracelet.healthData, info).then((res) => {
      this.healthData = res
    })
  }

  getLineData () {
    const info = {
      workerId: this.info.workerId,
      startTime: this.date[0],
      endTime: this.date[1]
    }
    this.$axios.get(this.$apis.bracelet.lineHealthData, info).then((res) => {
      this.lineHealthData = res || {}
      this.heartEchart()
      this.bloodEchart()
    })
  }

  heartEchart () {
    this.$nextTick(() => {
      this.heartLineEchart = Echarts.init(this.$refs.heartEcharts as any)
      const option = {
        color: ['rgba(47, 194, 90, 1)'],
        grid: {
          bottom: 30,
          top: 60,
          left: 55,
          right: 30
        },
        title: {
          text: '心率健康数据（次/min）',
          x: 'left',
          color: '#000',
          textStyle: {
            fontWeight: 'bold',
            fontSize: '18'
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: this.lineHealthData.collectTime
        },
        yAxis: {
          type: 'value',
          offset: -2,
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '心率',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          markLine: {
            lineStyle: {
              width: 2
            },
            label: {
              position: 'start',
              color: 'rgba(47, 194, 90, 1)'
            },
            symbolSize: 0,
            data: [{
              yAxis: 100,
              lineStyle: {
                color: 'rgba(47, 194, 90, 1)'
              }
            }, {
              yAxis: 60,
              lineStyle: {
                color: 'rgba(47, 194, 90, 1)'
              }
            }]
          },
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(47, 194, 90, 0.2)',
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: 'rgba(47, 194, 90, 1)'
            }
          },
          data: this.lineHealthData.rate
        }]
      }
      this.heartLineEchart.setOption(option)
    })
  }

  bloodEchart () {
    this.$nextTick(() => {
      this.bloodEcharts = Echarts.init(this.$refs.bloodEcharts as any)
      const option = {
        color: ['rgba(98, 54, 255, 1)', 'rgba(224, 32, 201, 1)'],
        grid: {
          bottom: 30,
          top: 80,
          left: 55,
          right: 30
        },
        title: {
          text: '血压健康数据（mmHg）',
          x: 'left',
          color: '#000',
          textStyle: {
            fontWeight: 'bold',
            fontSize: '18'
          }
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        legend: {
          type: 'plain',
          left: 25,
          top: 40,
          icon: 'rect',
          itemHeight: 2,
          itemWidth: 12,
          itemGap: 16,
          textStyle: {
            color: 'rgba(140, 140, 140, 1)'
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: this.lineHealthData.collectTime
        },
        yAxis: {
          type: 'value',
          offset: -2,
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '收缩压',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          markLine: {
            lineStyle: {
              width: 2
            },
            label: {
              position: 'start',
              color: 'rgba(98, 54, 255, 1)'
            },
            symbolSize: 0,
            data: [{
              yAxis: 120,
              lineStyle: {
                color: 'rgba(98, 54, 255, 1)'
              }
            }, {
              yAxis: 80,
              lineStyle: {
                color: 'rgba(98, 54, 255, 1)'
              }
            }]
          },
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(98, 54, 255, 0.2)',
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: 'rgba(98, 54, 255, 1)'
            }
          },
          data: this.lineHealthData.highPressure
        }, {
          name: '舒张压',
          type: 'line',
          symbolSize: 10,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(224, 32, 201, 0.2)',
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: 'rgba(224, 32, 201, 1)'
            }
          },
          data: this.lineHealthData.lowPressure
        }
        ]
      }
      this.bloodEcharts.setOption(option)
    })
  }

  eChartResize () {
    this.heartLineEchart && this.heartLineEchart.resize()
    this.bloodEcharts && this.bloodEcharts.resize()
  }

  destoryCharts () {
    window.removeEventListener('resize', this.eChartResize)
    this.heartLineEchart && this.heartLineEchart.dispose()
    this.bloodEcharts && this.bloodEcharts.dispose()
    this.heartLineEchart = null
    this.bloodEcharts = null
  }

  onSearch () {
    this.getData()
    this.getLineData()
  }
}
